<template>
  <div class="mainpage">
    <div class="head">
      <el-button :disabled="subbtstatus" class="bt bt1" @click="toback">返回</el-button>
      <el-button :disabled="subbtstatus" class="subbt" @click="submitbt(1)">提交</el-button>
    </div>
    <el-row class="bodycontain">
      <!-- 左侧答题 -->
      <el-col class="elcolleft" :span="16">
        <div class="leftcontain">
          <div class="title">{{ testInfo.test_name }} {{ "(" + testInfo.test_score + "分)" }}</div>
          <div class="smaincontain">
            <!-- 单选题 -->
            <div class="item-contain">
              <div
                class="titel2"
              >一、单选题（共{{ singlechoice.length }}题，{{ singlechoice | scoreformat }}分）</div>
              <template v-if="singlechoice && singlechoice.length > 0">
                <div
                  class="item"
                  v-for="(item, index) in singlechoice"
                  :key="index"
                  :id="'_item_0_' + index"
                >
                  <div class="titel3">
                    <div>{{ index + 1 + "、" }}</div>
                    <div
                      :class="['ask', item.show_title_all?'askWhole':'askPiece']"
                      @click="handleShowWhole(item, index)"
                    >
                      <div v-html="item.title"></div>
                    </div>
                    <div>{{ "(" + item.score + "分)" }}</div>
                  </div>
                  <div class="rediobt" v-for="(o, i) in item.option" :key="i">
                    <div
                      @click="clickbt1(item, i)"
                      :class="o.is_check == 1 ? 'icon iconactive' : 'icon'"
                    >{{ i | iconformat }}</div>
                    <div class="name answer" v-html="o.content"></div>
                  </div>
                </div>
              </template>
            </div>
            <!-- 多选题 -->
            <div class="item-contain">
              <div
                class="titel2"
              >二、多选题（共{{ multiplechoice.length }}题，{{ multiplechoice | scoreformat }}分）</div>
              <template v-if="multiplechoice && multiplechoice.length > 0">
                <div
                  class="item"
                  v-for="(item, index) in multiplechoice"
                  :key="index"
                  :id="'_item_1_' + index"
                >
                  <div class="titel3">
                    <span>{{ index + 1 + "、" }}</span>
                    <div
                      :class="['ask', item.show_title_all?'askWhole':'askPiece']"
                      @click="handleShowWhole(item, index)"
                    >
                      <div v-html="item.title"></div>
                    </div>
                    <span>{{ "(" + item.score + "分)" }}</span>
                  </div>
                  <div class="rediobt" v-for="(o, i) in item.option" :key="i">
                    <div
                      @click="clickbt2(item, i)"
                      :class="o.is_check == 1 ? 'icon iconactive' : 'icon'"
                    >{{ i | iconformat }}</div>
                    <div class="name answer" v-html="o.content"></div>
                  </div>
                </div>
              </template>
            </div>
            <!-- 判断题 -->
            <div class="item-contain">
              <div class="titel2">三、判断题（共{{ trueorfalse.length }}题，{{ trueorfalse | scoreformat }}分）</div>
              <template v-if="trueorfalse && trueorfalse.length > 0">
                <div
                  class="item"
                  v-for="(item, index) in trueorfalse"
                  :key="index"
                  :id="'_item_2_' + index"
                >
                  <div class="titel3">
                    <span>{{ index + 1 + "、" }}</span>
                    <div
                      :class="['ask', item.show_title_all?'askWhole':'askPiece']"
                      @click="handleShowWhole(item, index)"
                    >
                      <div v-html="item.title"></div>
                    </div>
                    <span>{{ "(" + item.score + "分)" }}</span>
                  </div>
                  <el-radio-group class="radiogroup" v-model="item.option[0].is_check">
                    <el-radio :label="1" @click.native.prevent="clickbt3(item, 1)">正确</el-radio>
                    <el-radio :label="2" @click.native.prevent="clickbt3(item, 2)">错误</el-radio>
                  </el-radio-group>
                </div>
              </template>
            </div>
            <!-- 填空题 -->
            <div class="item-contain">
              <div
                class="titel2"
              >四、填空题（共{{ fillintheblank.length }}题，{{ fillintheblank | scoreformat }}分）</div>
              <template v-if="fillintheblank && fillintheblank.length > 0">
                <div
                  class="item"
                  v-for="(item, index) in fillintheblank"
                  :key="index"
                  :id="'_item_3_' + index"
                >
                  <div class="titel3">
                    <span>{{ index + 1 + "、" }}</span>
                    <div
                      :class="['ask', item.show_title_all?'askWhole':'askPiece']"
                      @click="handleShowWhole(item, index)"
                    >
                      <div v-html="item.title"></div>
                    </div>
                    <span>{{ "(" + item.score + "分)" }}</span>
                  </div>
                  <div class="rediobt" v-for="(o, i) in item.option" :key="i">
                    <div
                      @click="clickbt1(item.option, i)"
                      :class="o.is_check == 1 ? 'icon iconactive' : 'icon'"
                    >{{ i }}</div>
                    <div class="name">
                      <!-- <el-input placeholder="请输入内容" v-model="o.content" @input="clickbt4(item)"></el-input> -->
                      <Tinymce
                        ref="editor"
                        :qid="item.stem_id"
                        :aid="o.id"
                        v-model="o.content"
                        :height="150"
                        style="height: auto; border-radius: 22px"
                        @setInput="getValue"
                        :valchange="clickbt4(item, i)"
                      ></Tinymce>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <!-- 简答题 -->
            <div class="item-contain">
              <div class="titel2">五、简答题（共{{ subjective.length }}题，{{ subjective | scoreformat }}分）</div>
              <template v-if="subjective && subjective.length > 0">
                <div
                  class="item"
                  v-for="(item, index) in subjective"
                  :key="index"
                  :id="'_item_4_' + index"
                >
                  <div class="titel3">
                    <span>{{ index + 1 + "、" }}</span>
                    <div :class="['ask', item.show_title_all?'askWhole':'askPiece']">
                      <div v-html="item.title"></div>
                      <div class="more" @click="handleShowWhole(item, index)"></div>
                    </div>
                    <span>{{ "(" + item.score + "分)" }}</span>
                  </div>
                  <div class="text-editor">
                    <Tinymce
                      ref="editor"
                      :qid="item.stem_id"
                      :aid="item.option[0].id"
                      v-model="item.option[0].content"
                      style="height: auto; border-radius: 22px"
                      @setInput="getValue"
                      :valchange="clickbt5(item)"
                    ></Tinymce>
                  </div>
                  <div class="text-file">
                    <span>附件：</span>
                    <UploadFiles
                      :files="fileList"
                      :qid="item.stem_id"
                      :aid="item.option[0].id"
                      :module="'test_period_stem_user_file,url'"
                      :cloud-type="'testPeriodStemUserFile/url'"
                      @setUrl="getUrl"
                    />
                  </div>
                </div>
              </template>
            </div>
            <div class="item-contain-empty"></div>
          </div>
        </div>
      </el-col>
      <!-- 左侧答题 -->
      <!-- 右侧目录 -->
      <el-col class="elcolright" :span="8">
        <div class="rightcontain">
          <div class="title2">
            <div class="text">截止时间： {{ testInfo.test_time }}</div>
          </div>
          <div class="maincontain">
            <div class="itemcontain">
              <div class="title3">一、单选题</div>
              <template v-if="singlechoice && singlechoice.length > 0">
                <div class="item3" v-for="(item, index) in singlechoice" :key="index">
                  <div class="outcircle" v-show="rightactiveid == item.stem_id">
                    <div class="innercircle"></div>
                  </div>
                  <div class="rightbt">
                    <div class="num" @click="rightbtclick(item)">
                      <div>{{ index + 1 + "、" }}</div>
                      <div
                        class="textbt"
                        v-scroll-to="{ element: '#_item_0_' + index, offset: 0, container: '.smaincontain'}"
                      >
                        <div class="ask askPiece" v-html="item.title"></div>
                      </div>
                    </div>
                    <el-checkbox v-model="item.is_check"></el-checkbox>
                  </div>
                </div>
              </template>
            </div>
            <div class="itemcontain">
              <div class="title3">二、多选题</div>
              <template v-if="multiplechoice && multiplechoice.length > 0">
                <div class="item3" v-for="(item, index) in multiplechoice" :key="index">
                  <div class="outcircle" v-show="rightactiveid == item.stem_id">
                    <div class="innercircle"></div>
                  </div>
                  <div class="rightbt">
                    <div class="num" @click="rightbtclick(item)">
                      <div>{{ index + 1 + "、" }}</div>
                      <div
                        class="textbt"
                        v-scroll-to="{ element: '#_item_1_' + index, offset: 0, container: '.smaincontain'}"
                      >
                        <div class="ask askPiece" v-html="item.title"></div>
                      </div>
                    </div>
                    <el-checkbox v-model="item.is_check"></el-checkbox>
                  </div>
                </div>
              </template>
            </div>
            <div class="itemcontain">
              <div class="title3">三、判断题</div>
              <template v-if="trueorfalse && trueorfalse.length > 0">
                <div class="item3" v-for="(item, index) in trueorfalse" :key="index">
                  <div class="outcircle" v-show="rightactiveid == item.stem_id">
                    <div class="innercircle"></div>
                  </div>
                  <div class="rightbt">
                    <div class="num" @click="rightbtclick(item)">
                      <div>{{ index + 1 + "、" }}</div>
                      <div
                        class="textbt"
                        v-scroll-to="{ element: '#_item_2_' + index, offset: 0, container: '.smaincontain'}"
                      >
                        <div class="ask askPiece" v-html="item.title"></div>
                      </div>
                    </div>
                    <el-checkbox class="answer" v-model="item.is_check"></el-checkbox>
                  </div>
                </div>
              </template>
            </div>
            <div class="itemcontain">
              <div class="title3">四、填空题</div>
              <template v-if="fillintheblank && fillintheblank.length > 0">
                <div class="item3" v-for="(item, index) in fillintheblank" :key="index">
                  <div class="outcircle" v-show="rightactiveid == item.stem_id">
                    <div class="innercircle"></div>
                  </div>
                  <div class="rightbt">
                    <div class="num" @click="rightbtclick(item)">
                      <div>{{ index + 1 + "、" }}</div>
                      <div
                        class="textbt"
                        v-scroll-to="{ element: '#_item_3_' + index, offset: 0, container: '.smaincontain'}"
                      >
                        <div class="ask askPiece" v-html="item.title"></div>
                      </div>
                    </div>
                    <el-checkbox v-model="item.is_check"></el-checkbox>
                  </div>
                </div>
              </template>
            </div>
            <div class="itemcontain">
              <div class="title3">五、简答题</div>
              <template v-if="subjective && subjective.length > 0">
                <div class="item3" v-for="(item, index) in subjective" :key="index">
                  <div class="outcircle" v-show="rightactiveid == item.stem_id">
                    <div class="innercircle"></div>
                  </div>
                  <div class="rightbt">
                    <div class="num" @click="rightbtclick(item)">
                      <div>{{ index + 1 + "、" }}</div>
                      <div
                        class="textbt"
                        v-scroll-to="{ element: '#_item_4_' + index, offset: 0, container: '.smaincontain'}"
                      >
                        <div class="ask askPiece" v-html="item.title"></div>
                      </div>
                    </div>
                    <el-checkbox v-model="item.is_check"></el-checkbox>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </el-col>
      <!-- 右侧目录 -->
    </el-row>
    <!-- 文件预览 -->
    <Customdialog
      class="shareDialog"
      ref="shareDialog"
      type="table"
      :title="showBigTip"
      :showclose="true"
      width="40%"
    >
      <div slot="dialogbody" class="dialogbody">
        <div class="bigShowing">
          <img class="showInfo" :src="showBigUrl" alt v-if="showBigType==1" />
          <video
            class="showInfo"
            controls
            controlslist="nodownload"
            v-if="showBigType==2"
            @contextmenu.prevent="handleRMouse"
          >
            <source :src="showBigUrl" type="video/mp4" />
          </video>
        </div>
      </div>
    </Customdialog>
  </div>
</template>

<script>
// 图片
import smallImg from "@/assets/fileicon/icon-img.png"
import smallVideo from "@/assets/fileicon/icon-video.png"
// 引入子组件
import Tinymce from "@/components/VueTinymce/index.vue" // 富文本
import UploadFiles from "@/components/upload-files.vue" // 文件上传
import Customdialog from "@/components/customdialog.vue" // 弹出框
// 获取接口
import { gettestperioduserInfo, testperioduseranswer, } from "@/api/testperioduser"

const iconmap = ["A", "B", "C", "D", "E", "F", "G"]
export default {
  name: "mainpage",
  components: {
    Tinymce,
    UploadFiles,
    Customdialog,
  },
  data () {
    return {
      test_period_id: "", // 考试ID
      courseinfo: "", // 课程信息

      testInfo: {}, // 试卷信息
      questions: [], // 试卷题目
      singlechoice: [], //单选题
      multiplechoice: [], //多选题
      trueorfalse: [], //判断题
      fillintheblank: [], //填空题
      subjective: [], //简答题

      rightactiveid: -1, // 右侧目录选中
      subbtstatus: false, // 按钮禁用，防抖动

      fileList: [], // 上传列表
      showBigType: 0,
      showBigTip: "",
      showBigUrl: "",
    }
  },
  filters: {
    scoreformat: function (value) {
      if (value && value.length > 0) {
        let score = 0
        value.forEach((item) => {
          score += item.score
        })
        return score
      } else {
        return ""
      }
    },
    iconformat: function (value) {
      return iconmap[value]
    },
  },
  computed: {},
  created () {
    window.showBig = this.showBig // 先将要插入的showBig方法绑定到全局上
    this.test_period_id = this.$route.query.test_period_id // 获取考试唯一ID
    this.courseinfo = decodeURIComponent(this.$route.query.courseinfo) // 获取课程信息并转码
    this.getTestInfo() // 获取试卷信息
  },
  mounted () {
    // 自动保存机制
    const timer = setInterval(() => {
      console.log("自动保存！")
      this.submitbt(0)
    }, 1000 * 60 * 2)
    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer)
    })
  },
  methods: {
    // 视频右键菜单
    handleRMouse (e) {
      e.preventDefault()
    },
    /**
     * 获取试卷信息,并处理
     * switch(){case…… } 分组题目
     */
    getTestInfo () {
      let params = { test_period_id: this.test_period_id }
      console.log("试卷参数：", params)
      gettestperioduserInfo(params).then((res) => {
        if (res.code === 0 && res.data) {
          // console.log("试卷数据：",res.data);
          if (Object.keys(res.data).length) {
            Object.keys(res.data).forEach(key => {
              if (key === "test_score") {
                res.data[key] = (typeof (res.data[key]) === 'undefined') || (res.data[key] === null) ? 0 : res.data[key]
              }
            })
          }
          this.testInfo = Object.assign({}, res.data) // 试卷信息
          delete this.testInfo.stem_list // 删除不必要信息
          if (res.data.stem_list) {
            this.singlechoice = [] //单选题
            this.multiplechoice = [] //多选题
            this.trueorfalse = [] //判断题
            this.fillintheblank = [] //填空题
            this.subjective = [] //简答题
            this.questions = res.data.stem_list.map(item => {
              /* 数据转译 */
              item.title = this.AutoCorrection(item.title) // 问题
              if (item.option && item.option.length > 0) {
                for (let i = 0; i < item.option.length; i++) {
                  let str = item.option[i].content
                  item.option[i].content = this.AutoCorrection(str) // 答案
                }
              }
              // 数据回填
              if (item.user_answer.answer && item.user_answer.answer.length > 0) {
                for (let index = 0; index < item.user_answer.answer.length; index++) {
                  const element = item.user_answer.answer[index]
                  // console.log("答案",element);
                  if (item.type >= 4) {
                    if (element.trim() && element != "<p></p>") {
                      item.option[index].is_check = 1
                    } else {
                      item.option[index].is_check = 0
                    }
                    item.option[index].content = element
                    item.option[index].file_list = item.user_answer.file_list
                  } else {
                    for (let i = 0; i < item.option.length; i++) {
                      if (item.option[i].id == parseInt(element)) {
                        item.is_check = true
                        item.option[i].is_check = 1 // 已答题
                        if (item.type === 3) {
                          item.option[0].is_check = i + 1
                        }
                      }
                    }
                  }
                }
              }
              /* 问答分类 */
              if (item.type == 1) {
                this.singlechoice.push(item) //单选题
              }
              if (item.type == 2) {
                this.multiplechoice.push(item) //多选题
              }
              if (item.type == 3) {
                this.trueorfalse.push(item) //判断题
              }
              if (item.type == 4) {
                this.fillintheblank.push(item) //填空题
              }
              if (item.type == 5) {
                this.subjective.push(item) //主观题
              }
              return item
            })
          }
        } else {
          console.log("试卷：", res.message)
        }
      }).catch((error) => {
        console.log("试卷：", error)
      })
    },

    /**
     * 循环修正数据与数据格式
     * 修改数据方法： str.replace() 与 str.replaceAll()
     * str = str.replaceAll("&nbsp;", ""); // 去除空格字符
     * 正则表达式： 
     * 全部空格(/\s+/g)，两侧空格(/^\s+|\s+$/g)，左侧空格(/^\s/)，右侧空格(/(\s$)/g)
     * 换行(/[\r\n]/g)，多余P标签(/<p>(\s|\&nbsp\;| |　|\xc2\xa0)*<\/p>/ig)，返斜杠(/\\/g)
     * 获取所有图片(/<img.+?>/ig)，获取图片标签(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi)
     */
    AutoCorrection (str) {
      const that = this
      let newStr = str.replaceAll(/&nbsp;/ig, "") //去除空格字符
      newStr = newStr.replace(/[\r\n]/g, "").trim() // 去与换行与前后空格
      newStr = newStr.replace(/\\/g, "") // 去除反斜杠
      newStr = newStr.replace(/\s+/g, " ") // 去除多余空格
      // newStr = newStr.replace(/<p>(\s|\&nbsp\;| |　|\xc2\xa0)*<\/p>/ig, ""); // 去掉多余 P 标签
      // 判断是否有图片，如果有图片
      let isHavePic = newStr.includes('<img')
      if (isHavePic) {
        // 正则过滤出图片
        newStr.replace(/<img.+?>/ig, function (matchImg, captureImg) {
          // console.log("正则匹配所有图片并获取相应下标==>",matchImg, captureImg);
          // newStr = newStr.replace(match,""); // 清除图片
          let resImgSrc = '' // 自定义变量接收图片
          matchImg.replace(/<img[^>]*src=['"]([^'"]+)[^>]*>/gi, function (matchSrc, captureSrc) {
            // console.log("正则匹配该图片并获取相应路径==>",matchSrc, captureSrc);
            resImgSrc = captureSrc.replace("../uploads", that.$api.baseUrl + "/uploads")
          })
          // 在图片中插入点击事件并默认不展示
          let newImgStr = matchImg.replace(/<s?img[^>]*src=['"]([^'"]+)/gi,
            `<img src="${smallImg}" onClick="showBig(event,'${resImgSrc}',1)" style="max-width:80%; margin: 4px;"`)
          newStr = newStr.replace(matchImg, newImgStr)
        })
      }
      // 判断是否有视频，如果有视频
      let isHaveMove = newStr.includes('<video')
      if (isHaveMove) {
        // 正则过滤出视频
        newStr.replace(/(<video[\s\S]*?>)[\s\S]*?(<\/video>)/ig, function (matchVideo, captureVideo) {
          // console.log("正则匹配所有视频并获取相应下标==>",matchVideo, captureVideo);
          // newStr = newStr.replace(matchVideo,""); // 清空视频
          let resMoveurl = '' // 自定义变量接收视频
          matchVideo.replace(/<source [^>]*src=['"]([^'"]+)[^>]*>/gi, function (matchUrl, captureUrl) {
            // console.log("正则匹配该视频并获取相应路径==>",matchUrl, captureUrl);
            resMoveurl = captureUrl.replace("../uploads", that.$api.baseUrl + "/uploads")
          })
          let newMoveStr = matchVideo.replace(/<s?video[\s\S]*?>[\s\S]*?<\/video>/gi,
            `<img src="${smallVideo}" onClick="showBig(event,'${resMoveurl}',2)" style="width:40px; height:40px margin: 4px;"/>`)
          newStr = newStr.replace(matchVideo, newMoveStr)
        })
      }
      return newStr
    },
    // 图片点击查看大图
    showBig (e, row, type) {
      e.stopPropagation()
      if (type == 1) {
        this.showBigTip = "图片预览"
      } else if (type == 2) {
        this.showBigTip = "视频预览"
      }
      this.showBigType = type
      this.showBigUrl = row
      this.$refs.shareDialog.dialogopenbt()
    },
    // 显示全部标题
    handleShowWhole (item, index) {
      // console.log("显示标题",item.show_title_all, index);
      if (item.show_title_all == 0) {
        item.show_title_all = 1
      } else {
        item.show_title_all = 0
      }
    },

    // 点击目录控制内容滚动到相应位置
    rightbtclick (item) {
      this.rightactiveid = item.stem_id
    },
    // 答题-单选选题
    clickbt1 (item, index) {
      // console.log("单选", item,index);
      if (item.length >= 0) {
        if (item[index].is_check == 0 && item[index].content) {
          item[index].is_check = 1
        } else {
          item[index].is_check = 0
        };
      } else {
        if (item.option[index].is_check == 1) {
          item.option = item.option.map(el => {
            el.is_check = 0
            return el
          })
          item.option[index].is_check = 0
          item.is_check = false
        } else {
          item.option = item.option.map(el => {
            el.is_check = 0
            return el
          })
          item.option[index].is_check = 1
          item.is_check = true
        }
      }
    },
    // 答题-多选选题
    clickbt2 (item, index) {
      // console.log("多选", item.option, index);
      if (item.option[index].is_check == 0) {
        item.option[index].is_check = 1
      } else {
        item.option[index].is_check = 0
      }
      let ischeck = item.option.some(el => el.is_check == 1)
      if (ischeck) {
        item.is_check = true
      } else {
        item.is_check = false
      }
    },
    // 答题-判断题
    clickbt3 (item, type) {
      //console.log("判断", item, type);
      if (type == 1) {
        item.option[0].is_check = 1
      } else if (type == 2) {
        item.option[0].is_check = 2
      }
      item.is_check = true
    },
    // 答题-填空题
    clickbt4 (item, index) {
      // console.log("填空", item,index);
      // 先判断该题是否已经填写答案
      let ischeck = item.option.every((ele) => {
        return ele.content != ""
      })
      if (ischeck) {
        item.is_check = true
      } else {
        item.is_check = false
      }
      // 在判断该题的各个空格是否已经填写答案
      if (item.option[index].content && item.option[index].content != "<p></p>") {
        item.option[index].is_check = 1
      } else {
        item.option[index].is_check = 0
      };
    },
    // 答题-问答题
    clickbt5 (item) {
      //console.log("问答", item);
      if (item.option[0].content != "") {
        item.is_check = true
      } else {
        item.is_check = false
      }
    },
    // 获取富文本得值
    getValue (data, key) {
      //console.log(data,key);
      this.questions.forEach((item) => {
        //console.log("问答-问",item);
        if (item.stem_id == key.qid) {
          item.option.forEach((ele) => {
            if (ele.id == key.aid) {
              //console.log("问答-答",ele);
              ele.content = data
            }
          })
        }
      })
    },
    // 获取文件上传的返回值
    getUrl (data, key) {
      // 循环将附件放入相应问答题
      this.subjective.forEach((item) => {
        if (item.stem_id == key.qid) {
          item.option.forEach((ele) => {
            // console.log("问答-答",ele);
            if (ele.id == key.aid) {
              ele.file_list = data
              // if (key.num == 2) {
              //   //console.log(ele.file_list);
              //   let newArr = []
              //   for (let i = 0; i < ele.file_list.length; i++) {
              //     for (let j = 0; j < newData.length; j++) {
              //       if (ele.file_list[i].name == newData[j].name) {
              //         newArr.push(ele.file_list[i])
              //       }
              //     }
              //   }
              //   ele.file_list = newArr
              // } else {
              //   ele.file_list = newData
              // }
            }
          })
        }
      })
    },

    /* 考试操作 */
    // 返回退出考试
    toback () {
      this.$router.replace({
        path: "/home/personalcenter/homework",
        query: {
          related_id: this.$route.query.related_id,
        }
      })
    },
    // 查看答题详情
    todetail () {
      this.$router.push({
        path: "/personalcenter/interactiveqadetails",
      })
    },

    // 试卷提交
    submitbt (status) {
      this.subbtstatus = true // 打开禁用按钮，防止重复提交
      let user_answer = [] // 定义数组，接收用户答题数据
      //单选题
      if (this.singlechoice && this.singlechoice.length > 0) {
        this.singlechoice.forEach((item0) => {
          let answerid = ""
          item0.option.forEach((item1) => {
            if (item1.is_check) {
              answerid = item1.id
            }
          })
          user_answer.push({
            test_stem_id: item0.test_stem_id,
            answer: answerid,
          })
        })
      }
      //console.log("单选题==>", user_answer);
      // debugger
      //多选题
      if (this.multiplechoice && this.multiplechoice.length > 0) {
        this.multiplechoice.forEach((item0) => {
          let answerid = []
          item0.option.forEach((item1) => {
            if (item1.is_check) {
              answerid.push(item1.id)
            }
          })
          user_answer.push({
            test_stem_id: item0.test_stem_id,
            answer: answerid.length > 0 ? answerid.join("{{#%|*@`}}") : "",
          })
        })
      }
      //console.log("多选题==>", user_answer);
      // debugger
      //判断题
      if (this.trueorfalse && this.trueorfalse.length > 0) {
        this.trueorfalse.forEach((item0) => {
          let answerid = ""
          if (item0.option[0].is_check == 1) {
            answerid = item0.option[0].id
          } else if (item0.option[0].is_check == 2) {
            answerid = item0.option[1].id
          }
          user_answer.push({
            test_stem_id: item0.test_stem_id,
            answer: answerid,
          })
        })
      }
      //console.log("判断题==>", user_answer);
      // debugger
      //填空题
      if (this.fillintheblank && this.fillintheblank.length > 0) {
        this.fillintheblank.forEach((item0) => {
          let answerid = []
          item0.option.forEach((item1) => {
            answerid.push(item1.content)
          })
          user_answer.push({
            test_stem_id: item0.test_stem_id,
            answer: answerid.length > 0 ? answerid.join("{{#%|*@`}}") : "",
          })
        })
      }
      //console.log("填空题==>", user_answer);
      // debugger
      //简答题
      if (this.subjective && this.subjective.length > 0) {
        this.subjective.forEach((item0) => {
          user_answer.push({
            test_stem_id: item0.test_stem_id,
            answer: item0.option[0].content,
            file_list: item0.option[0].file_list,
          })
        })
      }
      //console.log("简答题==>", user_answer);
      // debugger
      let params = {
        test_period_id: this.test_period_id, // 考试ID
        status: status, // 提交状态
        user_answer: JSON.stringify(user_answer), // 用户答题数据
      }
      // 判断提交方式
      if (status == 0) {
        this.submitTestAnswer(params, status) // 提交答案到后台
      } else if (status == 1) {
        // 选择是否提交
        this.$confirm("是否确认提交?", "提交后,批阅过后将不能再次修改。", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          if (this.uploadNum % 2) { // 判断附件是否传完
            this.$message.warning("附件正在上传，请稍后再提交")
            return false
          }
          this.submitTestAnswer(params, status) // 提交答案到后台
        }).catch(() => {
          this.subbtstatus = false // 接口请求取消，回复按钮
          this.$message({
            type: "info",
            message: "已取消提交",
          })
        })
      }
    },
    //提交答案到后台 status 0 保存 1提交 
    submitTestAnswer (params, status) {
      testperioduseranswer(params).then((response) => {
        this.subbtstatus = false // 接口请求成功，回复按钮
        if (response && response.code === 0) {
          if (status == 0) {
            this.$message({
              message: "自动保存!",
              type: "success",
            })
          } else if (status == 1) {
            this.$message({
              message: "提交成功!",
              type: "success",
            })
            this.toback()
          }
        }
      }).catch((error) => {
        //console.log(error);
      })
    },
  },
};
</script>

<style scoped lang="scss">
.mainpage {
  background: #ffffff;
  .head {
    padding-top: 40px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .head-title {
      font-size: 20px;

      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }

    .bt {
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #3d84ff;
      border-radius: 2px;
      padding: 0px;
      margin: 0px;
      color: #3d84ff;
    }

    .bt1 {
      margin-right: 10px;
      padding: 0px;
    }

    .subbt {
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      color: #ffffff;
      border: 1px solid #3d84ff;
      border-radius: 2px;
      padding: 0px;
      margin: 0px;
      background: #3d84ff;
    }
  }

  .bodycontain {
    margin-top: 15px;
    height: 960px;

    .elcolleft {
      height: 100%;

      box-sizing: border-box;
      padding: 10px;

      .leftcontain {
        height: 100%;
        background: #f7f7f7;
        box-sizing: border-box;
        padding: 20px;

        .title {
          font-size: 20px;
          font-weight: bold;
          color: #222222;
        }

        .smaincontain {
          margin-top: 40px;
          overflow-y: auto;
          height: 800px;

          .item-contain {
            margin-bottom: 30px;

            width: 98%;

            .titel2 {
              font-size: 18px;
              font-weight: bold;
              color: #222222;
            }

            .item {
              .titel3 {
                margin-top: 10px;
                margin-left: 35px;
                font-size: 16px;
                font-weight: 400;
                color: #222222;
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
              }

              .rediobt {
                margin-top: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                margin-left: 70px;

                .icon {
                  width: 40px;
                  min-width: 40px;
                  height: 40px;
                  line-height: 40px;
                  text-align: center;
                  border: 1px solid #cccccc;
                  border-radius: 50%;
                  user-select: none;
                  cursor: pointer;
                }

                .iconactive {
                  background: #3d84ff;
                  color: white;
                }

                .name {
                  margin-left: 20px;
                  font-size: 16px;

                  font-weight: 400;
                  color: #222222;
                }
              }

              .radiogroup {
                margin-left: 80px;
              }

              .text-editor {
                margin-top: 20px;
                margin-left: 35px;
              }

              .text-file {
                margin-top: 20px;
                margin-left: 35px;
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
              }
            }
          }

          .item-contain-empty {
            height: 200px;
          }
        }
      }
    }

    .elcolright {
      height: 100%;

      box-sizing: border-box;
      padding: 10px;
      box-sizing: border-box;

      .rightcontain {
        max-height: 800px;
        padding: 20px;
        background: #f7f7f7;
        overflow-y: auto;

        .title2 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 10px;
          border-bottom: 1px solid #b3b3b3;

          .text {
            margin-left: 10px;
            font-size: 20px;

            font-weight: 500;
            color: #222222;
          }
        }

        .maincontain {
          border-left: 2px solid #e8e8e8;
          margin-top: 40px;
          margin-left: 30px;

          .itemcontain {
            margin-bottom: 10px;

            .title3 {
              margin-left: 30px;
              font-size: 18px;
              font-weight: 700;
              color: #222222;
            }

            .item3 {
              margin-top: 10px;
              margin-left: -8px;
              margin-bottom: 20px;
              font-size: 16px;
              font-weight: 400;
              color: #222222;
              user-select: none;
              cursor: pointer;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .outcircle {
                background: #3d84ff;
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;

                .innercircle {
                  background: #ffffff;
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }

              .rightbt {
                margin-left: 50px;
                display: flex;
                flex: 1;
                justify-content: space-between;
                align-items: center;

                .num {
                  display: flex;
                  justify-content: flex-start;
                  align-items: baseline;

                  .textbt {
                    min-width: 300px;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ::v-deep .ask {
    width: calc(100% - 100px);
    line-height: 30px;
    text-indent: 1em;
    cursor: pointer;

    ol {
      list-style-type: none;
      padding: 0;
    }
    p {
      padding: 0 0;
      margin: 0 0;
    }
    img,
    video {
      width: auto;
      height: 30px;
      vertical-align: middle;
      margin: 0 5px;
    }
  }
  .askWhole {
    height: auto !important;
  }
  ::v-deep .askPiece {
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    word-break: break-all;
    -webkit-box-orient: vertical;
  }

  ::v-deep .answer {
    height: auto !important;

    ol {
      list-style-type: none;
      padding: 0;
    }
    p {
      line-height: 1.5;
      padding: 0 0;
      margin: 0 0;
    }

    img,
    video {
      width: auto;
      height: 100px;
      vertical-align: middle;
    }
  }

  .bigShowing {
    text-align: center;
    .showInfo {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      padding: 10px;
    }
  }
}
</style>
